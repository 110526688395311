<template>
	<div class="main">
    
	  <div class="main-container" v-if="option == 'individual'">
      <div>
        <h6 style="color:black;">Name Screening (CPY-Namescreening)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>Criteria</th>
                                    <th>Primary</th>
                                    <th>Score</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>CRV 1</td>
                                  <td>No Match</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>CRV 2</td>
                                  <td>Company Name+Country</td>
                                  <td>5</td>
                                </tr>
                                <tr>
                                  <td>CRV 3</td>
                                  <td>Company Name+Address</td>
                                  <td>2.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 4</td>
                                  <td>Company Name + BRN</td>
                                  <td>1</td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
      </div>
      <div>
        <h6 style="color:black;">Years in Business (CPY-Years in Business)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>No of years in Industry</th>
                                    <th>Score</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>≥ 5</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>≥ 2 but less than 5h</td>
                                  <td>8.5</td>
                                </tr>
                                <tr>
                                  <td>≥ 1</td>
                                  <td>7.5</td>
                                </tr>
                                <tr>
                                  <td>≥ 6 mos</td>
                                  <td>5</td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
      </div>
      <div>
        <h6 style="color:black;">Risk Jurisdiction (CPY-Risk Jurisdiction)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>Risk Jurisdiction</th>
                                    <th>Risk Level</th>
                                    <th>Score</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Non Sanctioned Country</td>
                                  <td>Low</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Grey List Country</td>
                                  <td>Medium</td>
                                  <td>5</td>
                                </tr>
                                <tr>
                                  <td>UNSC Sanctioned Country</td>
                                  <td>High</td>
                                  <td>0</td>
                                </tr>
                               
                              </tbody>
                          </table>
                        </div>
      </div>
            <div> 
              <h6 style="color:black;">Business Size (CPY-Business Size)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>Number of Employees</th>
                                    <th>Scale</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>less than 20</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>≥ 20 but less than 50</td>
                                  <td>8.5</td>
                                </tr>
                                <tr>
                                  <td>≥ 50 but less than 100</td>
                                  <td>7</td>
                                </tr>
                                <tr>
                                  <td>≥ 100</td>
                                  <td>5</td>
                                </tr>
                               
                              </tbody>
                          </table>
                        </div>
              </div>
              <div> 
              <h6 style="color:black;">Operator Platform - availability of fields in operator's platform (CPY-Operator Platform)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>KYB Template Field</th>
                                    <th>Scale</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Company Name</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Registered Address</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Country of Establishment</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>DBA Name</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>DBA address</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>BRN</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Issuing Agency</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>No. of Years in Business</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Est Turnover</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Est No of Transaction/Customer</td>
                                  <td>10</td>
                                </tr>
                               
                              </tbody>
                          </table>
                        </div>
              </div>
      
      </div>
      

    
    </div>
</template>
<script>
  export default {
      name: 'Idv',
      data: function() {
        return { 
            option: 'individual',
            tab:'individualScore',
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
      },
      methods:{
      }
  }
</script>
<style scoped lang="scss">
.market-content {
  width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 300px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
</style>