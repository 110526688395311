<template>
	<div class="main">
         <TheTopbar/>
         <slot />
	<div class="main-container">
    <div class="d-flex">
      <div class="content-wrapper">
          <div class="d-flex justify-content-between modal-header m-3"> 
                <h5 >COMPLIANCE SETTINGS</h5>
            </div>
        <div class="modal-body">
              <div class="market-content">
                  <div class="d-flex justify-content-between"> 
                    <label style="color: black;">General Scale</label>
                    <button class="btn btn-secondary text-dark" @click="showAddItem()" :disabled="!init.FXCOMPLIANCE">+</button>
                  </div>
                
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        10 - low risk
                      </th>
                      <th>
                        9
                      </th>
                      <th>
                        8
                      </th>
                      <th>
                        7
                      </th>
                      <th>
                        6
                      </th>
                      <th>
                        5 
                      </th>
                      <th>
                        4
                      </th>
                      <th>
                        3 
                      </th>
                      <th>
                        2 
                      </th>
                      <th>
                        1
                      </th>
                      <th>
                        0 - high risk
                      </th>
                    </tr>
                  </thead>

                </table>
                <h4 style="color:black;">Guidelines</h4>
                <div >
                  
                <button  class="btn btn-secondary"  :class="{ active: state == 'individualScore'}" @click="opentab('individualScore')">
                 Individual Profile Score
                </button>
                <button  class="btn btn-secondary" :class="{ active: state == 'companyScore'}"  @click="opentab('companyScore')">
                 Company Profile Score
                </button>
                <button  class="btn btn-secondary" :class="{ active: state == 'txnMonitoring'}" @click="opentab('txnMonitoring')">
                  BEH Score_txn Monitoring
                </button>
                <button  class="btn btn-secondary" :class="{ active: state == 'riskLevel'}" @click="opentab('riskLevel')">
                  Risk Level Indicator
                </button>
                </div>
               
                <div v-if="tab == 'individualScore'">
                  <hr>
                  <Idv/>
                </div>
                <div v-if="tab == 'companyScore'">
                  <Cpy/>
                </div>
                <div v-if="tab == 'txnMonitoring'">
                 <Beh/>
                </div>
                <div v-if="tab == 'riskLevel'">
                  <img :src="require('../assets/compliance/riskLevel.png')" class="photo100"/>
                </div>
                <div class="market-content">
                  <button class="btn btn-secondary text-dark" @click="showAddItem()" :disabled="!init.FXCOMPLIANCE" v-b-tooltip.hover title="Click to add criteria for compliance">+</button>
                  <h5>Add Items to review</h5>
                  <table class="table">
                    <tbody v-if="itemList.length == 0">
                      <td>No item found</td>
                      <td></td>
                    </tbody>
                    <thead>
                      <tr>
                          <th></th>
                          <th></th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr v-for="item in itemList" :key="item.id">
                        <td>{{item.description}}</td>
                        <td @click="deleteItem(item)" :disabled="!init.FXCOMPLIANCE" v-b-tooltip.hover title="Click to delete">x</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  </div>
              
        </div>
        
        <hr>
       
      </div>
    </div>
    </div>
   
    <AddItem v-if="showAdd" @close="showAddItem()"></AddItem>
    <SendComment v-if="showComment" @close="addComment()"></SendComment>
	</div>
  
 
          
</template>
        
<script>
  import moment from 'moment';
  import Idv from '../components/modal/Compliance/Idv.vue'
  import Cpy from '../components/modal/Compliance/Cpy.vue'
  import Beh from '../components/modal/Compliance/Beh.vue'
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import AddItem from '../components/modal/Documents/AddItem.vue'
  import SendComment from '../components/modal/Documents/SendComment.vue'

  export default {
      name: 'ComplianceReview',
      components: {
        TheTopbar,
        Header,
        AddItem,
        SendComment,
        Idv,
        Cpy,
        Beh
      },
      
       data: function() {
      return { 
        isApproved: false,
        showUpload: false,
        itemList: [],
        showAdd: false,
        showComment: false,
        rating:'',
        comment:'',
        reviewId:'',
        tab:'individualScore',
        state:''

      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
      
     
      },
      methods:{
        opentab(tab) {
            this.tab = tab;
            localStorage.setItem("tab", tab);
        },
        showAddItem(){
         this.showAdd = !this.showAdd
        },
        addComment(){
          this.showComment = !this.showComment
        },
       deleteItem(item){
        this.reviewId = item.reviewId
        const params ={
          reviewId: this.reviewId
        }
        const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/review/items/delete'
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                window.location.reload();
                
               
              }).catch((err) => {
                  
              })
       },
        getListOfItems(){
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              url: '/ax/review/items'
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                this.itemList = response.data

                
               
              }).catch((err) => {
                  
              })
        },
       
       
       
          goBack(){
            window.location.href="/#/customer-checklist/" + this.$route.params.entityId
        },
          formatDate: function(time){
              return new Date().toDateString();
          },
          approve(){
            this.openApprove = !this.openApprove
          },
          reject(){
            this.openReject = !this.openReject
          },
         
        
          toggleCountryList: function(){
              this.showCountry = !this.showCountry;
              
              },
          
          selectCountry: function(c) {
              this.showCountry = false;
              this.country = c.countryCode;
              this.mobileCode = c.mobileCode;
          },
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
          isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
              evt.preventDefault();
              } else {
              return true;
              }
          },
          cancelEditMobile(){
              this.isEditingMobile = false;
              window.location.reload();
          },
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
            this.axios(options)
              .then((response) => {
             
              }).catch((err) => {
                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getListOfItems();
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
    color: white;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  
    .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #FFFFFF; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
   
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
 .photo{
  width: 50%;
 }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  


@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: gray !important;
  color: black;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
