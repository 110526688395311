<template>
	<div class="main">
    
	  <div class="main-container" v-if="option == 'individual'">
      <div>
        <h6 style="color:black;">Name Screening of Individual or Associates (IDV-Profile Screening)</h6>
      <p style="color:black;">*Average of total Individual Result equals total Name Scan Score</p>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>Criteria</th>
                                    <th>Primary</th>
                                    <th>Score</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>CRV 1</td>
                                  <td>No Match</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>CRV 2</td>
                                  <td>LN+DOB+NAT</td>
                                  <td>7.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 3</td>
                                  <td>FN+LN+Nat</td>
                                  <td>5</td>
                                </tr>
                                <tr>
                                  <td>CRV 4</td>
                                  <td>FN+DOB+Nat</td>
                                  <td>6</td>
                                </tr>
                                <tr>
                                  <td>CRV 5</td>
                                  <td>LN+DOB+PC</td>
                                  <td>6</td>
                                </tr>
                                <tr>
                                  <td>CRV 6</td>
                                  <td>FN+LN+YOB+PC</td>
                                  <td>5</td>
                                </tr>
                                <tr>
                                  <td>CRV 7</td>
                                  <td>FN+LN+DOB</td>
                                  <td>2.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 8</td>
                                  <td>FN+MI+LN</td>
                                  <td>2.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 9</td>
                                  <td>FN+DOB+NAT+PC</td>
                                  <td>4</td>
                                </tr>
                                <tr>
                                  <td>CRV 10</td>
                                  <td>FN+LN+Nat+PC</td>
                                  <td>4</td>
                                </tr>
                                <tr>
                                  <td>CRV 11</td>
                                  <td>FN+LN+YOB</td>
                                  <td>3.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 12</td>
                                  <td>FN+MD+YOB+NAT</td>
                                  <td>3.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 13</td>
                                  <td>FN+LN+DOB+NAT</td>
                                  <td>1.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 14</td>
                                  <td>FN+MN+DOB+NAT</td>
                                  <td>1.5</td>
                                </tr>
                                <tr>
                                  <td>CRV 15</td>
                                  <td>FN+MI+DOB+NAT</td>
                                  <td>2</td>
                                </tr>
                                <tr>
                                  <td>CRV 16</td>
                                  <td>FN+LN+NAT+ID</td>
                                  <td>1</td>
                                </tr>
                                <tr>
                                  <td>CRV 17</td>
                                  <td>FN+LN+DOB+ID</td>
                                  <td>1</td>
                                </tr>


                              </tbody>
                          </table>
                        </div>
      </div>
      <div>
        <h6 style="color:black;">Operator Platform - availability of fields in operator's platform (IDV-Platform Platform)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>KYC Template Field for Players</th>
                                    <th>Score</th>
                                    <th>Scale</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Full Name</td>
                                  <td>1</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Date of Birth</td>
                                  <td>1</td>
                                  <td>9</td>
                                </tr>
                                <tr>
                                  <td>Gender</td>
                                  <td>0.5</td>
                                  <td>8</td>
                                </tr>
                                <tr>
                                  <td>Nationality</td>
                                  <td>1</td>
                                  <td>7</td>
                                </tr>
                                <tr>
                                  <td>Place of Birth</td>
                                  <td>1</td>
                                  <td>6</td>
                                </tr>
                                <tr>
                                  <td>Address</td>
                                  <td>1</td>
                                  <td>5</td>
                                </tr>
                                <tr>
                                  <td>Contact Number or Information</td>
                                  <td>1</td>
                                  <td>4</td>
                                </tr>
                                <tr>
                                  <td>Signature/Declaration and Authentication</td>
                                  <td>1</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Source of Fund</td>
                                  <td>0.5</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Liveness</td>
                                  <td>1</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Risk Level Indicator</td>
                                  <td>1</td>
                                  <td></td>
                                </tr>
                                
                              </tbody>
                          </table>
                        </div>
      </div>
      <div>
        <h6 style="color:black;">Identification Requirement - Submitted Identity requirement of Associates (IDV-Identification Requirement)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>ID Type Submitted</th>
                                    <th>Score</th>
                                    <th>Scale</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Driverse License</td>
                                  <td>10</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>National ID</td>
                                  <td>10</td>
                                  <td>9</td>
                                </tr>
                                <tr>
                                  <td>Passport</td>
                                  <td>9</td>
                                  <td>8</td>
                                </tr>
                                <tr>
                                  <td>Other</td>
                                  <td>8</td>
                                  <td></td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
      </div>
      <h6 style="color:black;">Identification Requirement - Submitted Identity requirement of Associates (IDV-Identification Requirement)</h6>
                  <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>Heightened Risk by Nationality</th>
                                    <th>Risk Level</th>
                                    <th>Scale</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Non Sanctioned Nationality</td>
                                  <td>Low</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <td>Grey List Nationality</td>
                                  <td>Medium</td>
                                  <td>5</td>
                                </tr>
                                <tr>
                                  <td>UNSC Sanctioned Nationality</td>
                                  <td>High</td>
                                  <td>0</td>
                                </tr>
                               
                              </tbody>
                          </table>
                        </div>
      </div>
      

    
    </div>
</template>
<script>
  export default {
      name: 'Idv',
      data: function() {
        return { 
            option: 'individual',
            tab:'individualScore',
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
      },
      methods:{
      }
  }
</script>
<style scoped lang="scss">
.market-content {
  width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 300px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
</style>